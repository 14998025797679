<template>
	<WSection :title="$t('everial.quickfolder_metadata') + ' :'">
		<template v-slot:header>
			<v-flex align-center d-flex mr-2 shrink>
				<w-text-info mini :text="$t('everial.quickfolder_metadata_info')" />
			</v-flex>
		</template>
		<v-layout row wrap>
			<w-text-input v-model="everialMetadata" :label="$t('everial.metadata_name')" :loading="loading" required @input="emitInput" />
		</v-layout>
	</WSection>
</template>

<script>
import EverialModuleGuard from '@/mixins/ModulesGuards/Everial/EverialModuleGuard'

export default {
	name: 'QuickfolderModelFormSection',
	mixins: [EverialModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			everialMetadata: null,
			loading: false
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (newVal, oldVal) {
				if (newVal && !newVal.id) {
					this.everialMetadata = null
				} else if (!oldVal || (newVal && newVal.id !== oldVal.id)) {
					this.getQuickfolderModelMetadata(newVal.id)
				}
			},
			immediate: true
		}
	},
	methods: {
		emitInput: function () {
			this.$emit('input', { ...this.value, everial_metadata: this.everialMetadata })
		},
		getQuickfolderModelMetadata: function (quickfolderModelId) {
			this.loading = true
			this.everialMetadata = null
			return this.service
				.getQuickfolderModelMetadata(quickfolderModelId)
				.then(metadata => {
					this.everialMetadata = metadata
					this.emitInput()
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>
