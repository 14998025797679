// ============================================================================
// EverialService
// --------------
// EverialService module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/EverialApi'

// -------
// Exports
// -------
export default {
	getQuickfolderModelMetadata: function (quickfolderModelId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.getQuickfolderModelMetadata(accountingFirmId, quickfolderModelId, { show_error: false })
			.then(res => res.data.data)
			.then(data => data.name)
			.catch(err => {
				if (err.response.status === 404) {
					return ''
				} else {
					return Promise.reject(err)
				}
			})
	}
}
