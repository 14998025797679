import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/everial/')

/**
 * @api GET /api/everial/{accountingFirmId}/quickfolder-models/{quickfolderModelId}/metadata
 *
 * @param {Number} accountingFirmId
 * @param {Number} quickfolderModelId
 * @param {Object} config
 */
const getQuickfolderModelMetadata = (accountingFirmId, quickfolderModelId, config) => {
	const url = `${accountingFirmId}/quickfolder-models/${quickfolderModelId}/metadata`
	return axios.get(url, config)
}

export default {
	getQuickfolderModelMetadata: getQuickfolderModelMetadata
}
